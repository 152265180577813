/* eslint-disable no-unused-vars */
import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Image, Typography, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import {
  FundTwoTone,
  MessageTwoTone,
  SolutionOutlined,
  FileAddOutlined,
  ShopTwoTone,
  BookTwoTone,
  IdcardTwoTone,
  // AppstoreTwoTone,
  ProfileTwoTone,
  RobotOutlined,
  PieChartTwoTone,
  // FolderOpenTwoTone,
  // TagTwoTone
  ApiOutlined,
} from "@ant-design/icons";
import { Menu } from "./elements";
import { useUser } from "providers/user";
import Logo from "./logo4.png";

const { Item, SubMenu } = Menu;
const { Text } = Typography;

const Sidebar = ({ history, collapsed, setCollapsed, isMobile }) => {
  const { user, logout } = useUser();

  const handleLogout = async () => {
    await logout();
  };

  let routes = (
    <>
      <Item
        style={{ marginBottom: isMobile ? 15 : undefined }}
        icon={<MessageTwoTone />}
        key="/chatbotNew"
        onClick={isMobile ? () => setCollapsed(!collapsed) : undefined}
      >
        <Link
          to={{
            pathname: "/chatbotNew",
            state: { isPrivateView: true },
          }}
        >
          <Text
            strong
            style={{ color: "white", fontSize: isMobile ? 22 : undefined }}
          >
            Chatbot
          </Text>
        </Link>
      </Item>
      <Item
        style={{ marginBottom: 10 }}
        icon={<FundTwoTone />}
        key="/dashboard"
        onClick={isMobile ? () => setCollapsed(!collapsed) : undefined}
      >
        <Link to="/dashboard">
          <Text
            strong
            style={{ color: "white", fontSize: isMobile ? 22 : undefined }}
          >
            Dashboard
          </Text>
        </Link>
      </Item>
      <SubMenu
        icon={<BookTwoTone />}
        title={
          <Text
            strong
            style={{ color: "white", fontSize: isMobile ? 22 : undefined }}
          >
            Tickets
          </Text>
        }
      >
        <Item
          style={{ marginBottom: 10 }}
          icon={<BookTwoTone />}
          key="/tickets"
          onClick={isMobile ? () => setCollapsed(!collapsed) : undefined}
        >
          <Link to="/tickets">
            <Text
              strong
              style={{ color: "white", fontSize: isMobile ? 22 : undefined }}
            >
              Soporte
            </Text>
          </Link>
        </Item>
        <Item
          style={{ marginBottom: 10 }}
          icon={<BookTwoTone />}
          key="/feedback"
          onClick={isMobile ? () => setCollapsed(!collapsed) : undefined}
        >
          <Link to="/feedback">
            <Text
              strong
              style={{ color: "white", fontSize: isMobile ? 22 : undefined }}
            >
              Retro-alimentación
            </Text>
          </Link>
        </Item>
      </SubMenu>
      <Item
        style={{ marginBottom: 10 }}
        icon={<ShopTwoTone />}
        key="/profile"
        onClick={isMobile ? () => setCollapsed(!collapsed) : undefined}
      >
        <Link to="/profile">
          <Text
            strong
            style={{ color: "white", fontSize: isMobile ? 22 : undefined }}
          >
            Chatbots
          </Text>
        </Link>
      </Item>
      <Item
        style={{ marginBottom: 10 }}
        icon={<IdcardTwoTone />}
        key="/users"
        onClick={isMobile ? () => setCollapsed(!collapsed) : undefined}
      >
        <Link to="/users">
          <Text
            strong
            style={{ color: "white", fontSize: isMobile ? 22 : undefined }}
          >
            Usuarios
          </Text>
        </Link>
      </Item>
      <Item
        style={{ marginBottom: 10 }}
        icon={<PieChartTwoTone />}
        key="/analytic"
        onClick={isMobile ? () => setCollapsed(!collapsed) : undefined}
      >
        <Link to="/analytic">
          <Text
            strong
            style={{ color: "white", fontSize: isMobile ? 22 : undefined }}
          >
            Análisis
          </Text>
        </Link>
      </Item>
      {/* 
      <Item style={{marginBottom: 10}} icon={<ProfileTwoTone />} key="/tiers" onClick={()=>setCollapsed(!collapsed)}>
        <Link to="/tiers">
          <Text strong style={{ color: "white", fontSize: isMobile ? 22 : undefined  }}>
            Planes
          </Text>
        </Link>
      </Item>

      <Item style={{marginBottom: 10}} icon={<AppstoreTwoTone />} key="/integrations" onClick={()=>setCollapsed(!collapsed)}>
        <Link to="/integrations">
          <Text strong style={{ color: "white", fontSize: isMobile ? 22 : undefined  }}>
            Integraciones
          </Text>
        </Link>
      </Item>

       <Item style={{marginBottom: 10}} icon={<FolderOpenTwoTone />} key="/invoice" onClick={()=>setCollapsed(!collapsed)}>
        <Link to="/invoice">
          <Text strong style={{ color: "white", fontSize: isMobile ? 22 : undefined  }}>
            Facturación
          </Text>
        </Link>
      </Item>
      <Item style={{marginBottom: 10}} icon={<TagTwoTone />} key="/billing" onClick={()=>setCollapsed(!collapsed)}>
        <Link to="/billing">
          <Text strong style={{ color: "white", fontSize: isMobile ? 22 : undefined  }}>
            Plan/Creditos
          </Text>
        </Link>
      </Item> */}
    </>
  );

  let routesShopifyClient = (
    <>
      <Item
        icon={<MessageTwoTone />}
        key="/chat"
        onClick={isMobile ? () => setCollapsed(!collapsed) : undefined}
      >
        <Link to="/chat">
          <Text
            strong
            style={{ color: "white", fontSize: isMobile ? 22 : undefined }}
          >
            Chatbot
          </Text>
        </Link>
      </Item>
      <Item
        icon={<FundTwoTone />}
        key="/dashboard"
        onClick={isMobile ? () => setCollapsed(!collapsed) : undefined}
      >
        <Link to="/dashboard">
          <Text
            strong
            style={{ color: "white", fontSize: isMobile ? 22 : undefined }}
          >
            Dashboard
          </Text>
        </Link>
      </Item>
      <Item
        icon={<BookTwoTone />}
        key="/tickets"
        onClick={isMobile ? () => setCollapsed(!collapsed) : undefined}
      >
        <Link to="/tickets">
          <Text
            strong
            style={{ color: "white", fontSize: isMobile ? 22 : undefined }}
          >
            Tickets Soporte
          </Text>
        </Link>
      </Item>
      <Item
        icon={<ShopTwoTone />}
        key="/profile"
        onClick={isMobile ? () => setCollapsed(!collapsed) : undefined}
      >
        <Link to="/profile">
          <Text
            strong
            style={{ color: "white", fontSize: isMobile ? 22 : undefined }}
          >
            Chatbots
          </Text>
        </Link>
      </Item>
    </>
  );

  let routesSupport = (
    <>
      <Item
        icon={<ProfileTwoTone />}
        key="/tickets"
        onClick={isMobile ? () => setCollapsed(!collapsed) : undefined}
      >
        <Link to="/tickets">
          <Text
            strong
            style={{ color: "white", fontSize: isMobile ? 22 : undefined }}
          >
            Calificar
          </Text>
        </Link>
      </Item>
      <Item
        icon={<BookTwoTone />}
        key="/examples"
        onClick={isMobile ? () => setCollapsed(!collapsed) : undefined}
      >
        <Link to="/examples">
          <Text
            strong
            style={{ color: "white", fontSize: isMobile ? 22 : undefined }}
          >
            Ejemplos
          </Text>
        </Link>
      </Item>
      <Item
        icon={<BookTwoTone />}
        key="/feedback"
        onClick={isMobile ? () => setCollapsed(!collapsed) : undefined}
      >
        <Link to="/feedback">
          <Text
            strong
            style={{ color: "white", fontSize: isMobile ? 22 : undefined }}
          >
            Retro-alimentación
          </Text>
        </Link>
      </Item>
    </>
  );

  let routesAdmin = (
    <>
      <Item
        icon={<FundTwoTone />}
        key="/dashboard"
        onClick={isMobile ? () => setCollapsed(!collapsed) : undefined}
      >
        <Link to="/dashboard">
          <Text
            strong
            style={{ color: "white", fontSize: isMobile ? 22 : undefined }}
          >
            Dashboard
          </Text>
        </Link>
      </Item>
      <Item
        icon={<SolutionOutlined style={{ color: "#ffffff" }} />}
        key="/clientes"
        onClick={isMobile ? () => setCollapsed(!collapsed) : undefined}
      >
        <Link to="/clientes">
          <Text
            strong
            style={{ color: "white", fontSize: isMobile ? 22 : undefined }}
          >
            Clientes
          </Text>
        </Link>
      </Item>
      <Item
        icon={<FileAddOutlined style={{ color: "#ffffff" }} />}
        key="/facturacion"
        onClick={isMobile ? () => setCollapsed(!collapsed) : undefined}
      >
        <Link to="/facturacion">
          <Text
            strong
            style={{ color: "white", fontSize: isMobile ? 22 : undefined }}
          >
            Facturacion
          </Text>
        </Link>
      </Item>
      <Item
        icon={<RobotOutlined style={{ color: "#ffffff" }} />}
        key="/chatbots"
        onClick={isMobile ? () => setCollapsed(!collapsed) : undefined}
      >
        <Link to="/chatbots">
          <Text
            strong
            style={{ color: "white", fontSize: isMobile ? 22 : undefined }}
          >
            Bots Genericos
          </Text>
        </Link>
      </Item>
      {/* <Item icon={<FileAddOutlined />} key="/logs">
        <Link to="/logs">
          <Text strong style={{ color: "white", fontSize: isMobile ? 22 : undefined }}>
            Logs Master
          </Text>
        </Link>
      </Item> */}
      <Item
        icon={<MessageTwoTone />}
        key="/shopify-bots"
        onClick={isMobile ? () => setCollapsed(!collapsed) : undefined}
      >
        <Link to="/shopify-bots">
          <Text
            strong
            style={{ color: "white", fontSize: isMobile ? 22 : undefined }}
          >
            Shopify Bots
          </Text>
        </Link>
      </Item>
      <Item
        icon={<PieChartTwoTone />}
        key="/analytic"
        onClick={isMobile ? () => setCollapsed(!collapsed) : undefined}
      >
        <Link to="/analytic">
          <Text
            strong
            style={{ color: "white", fontSize: isMobile ? 22 : undefined }}
          >
            Análisis
          </Text>
        </Link>
      </Item>
      <Item
        icon={<ApiOutlined />}
        key="/shopify-petitions"
        onClick={isMobile ? () => setCollapsed(!collapsed) : undefined}
      >
        <Link to="/shopify-petitions">
          <Text
            strong
            style={{ color: "white", fontSize: isMobile ? 22 : undefined }}
          >
            Peticiones
          </Text>
        </Link>
      </Item>
    </>
  );

  const userRoutes = {
    admin: routesAdmin,
    support: routesSupport,
    shopifyClient: routesShopifyClient,
  };

  return (
    <div
      className="sidebar"
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100hv",
        width: isMobile ? "100vw" : undefined,
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        {isMobile && (
          <Button
            type="primary"
            icon={<CloseOutlined />}
            onClick={() => {
              setCollapsed(true);
            }}
            size="small"
            style={{
              display: isMobile ? "block" : "none",
              position: "absolute",
              zIndex: 10,
              top: 10,
              right: 0,
              backgroundColor: "transparent",
              border: "none",
            }}
          />
        )}
        <Image
          style={{
            display: "block",
            paddingLeft: 30,
            paddingRight: 30,
            marginTop: 20,
            objectFit: "contain",
          }}
          preview={false}
          height={28}
          width={"90%"}
          src={Logo}
          alt="Logo"
        />

        <Menu
          theme="dark"
          style={{
            marginTop: 40,
            background: "none",
          }}
          defaultSelectedKeys={history.location.pathname.toLowerCase()}
          selectedKeys={history.location.pathname.toLowerCase()}
          mode="inline"
        >
          {user?.type === "admin"
            ? routesAdmin
            : user?.type === "support"
            ? routesSupport
            : routes}
        </Menu>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          padding: "15px 0 ",
        }}
      >
        <Button type="danger" onClick={() => handleLogout()}>
          Cerrar Session
        </Button>
      </div>
    </div>
  );
};

export default withRouter(Sidebar);
