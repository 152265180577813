/* eslint-disable no-unused-vars */
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import Loadable from "react-loadable";
import { useUser } from "providers/user";

const Dashboard = Loadable({
  loader: () => import("./routes/dashboard"),
  loading: TopBarProgress,
});

const Chat = Loadable({
  loader: () => import("./routes/chat"),
  loading: TopBarProgress,
});
const DataSource = Loadable({
  loader: () => import("./routes/datasource"),
  loading: TopBarProgress,
});
const Tickets = Loadable({
  loader: () => import("./routes/tickets"),
  loading: TopBarProgress,
});

const Billing = Loadable({
  loader: () => import("./routes/billing"),
  loading: TopBarProgress,
});

const Invoice = Loadable({
  loader: () => import("./routes/invoice"),
  loading: TopBarProgress,
});

const Profile = Loadable({
  loader: () => import("./routes/profile"),
  loading: TopBarProgress,
});

const Tiers = Loadable({
  loader: () => import("./routes/tiers"),
  loading: TopBarProgress,
});

const Users = Loadable({
  loader: () => import("./routes/users"),
  loading: TopBarProgress,
});

const Integrations = Loadable({
  loader: () => import("./routes/integrations"),
  loading: TopBarProgress,
});

const ChatBotConfig = Loadable({
  loader: () => import("./routes/chatBotConfig"),
  loading: TopBarProgress,
});

const Config = Loadable({
  loader: () => import("./routes/config"),
  loading: TopBarProgress,
});

const Analytic = Loadable({
  loader: () => import("./routes/analytic"),
  loading: TopBarProgress,
});

const Feedback = Loadable({
  loader: () => import("./routes/feedback"),
  loading: TopBarProgress,
});

//ADMIN
const DashboardAdmin = Loadable({
  loader: () => import("./routes/admin/dashboard"),
  loading: TopBarProgress,
});
const Clients = Loadable({
  loader: () => import("./routes/admin/clients"),
  loading: TopBarProgress,
});

const Invoices = Loadable({
  loader: () => import("./routes/admin/invoices"),
  loading: TopBarProgress,
});
const Chatbots = Loadable({
  loader: () => import("./routes/admin/chatbots"),
  loading: TopBarProgress,
});
const Logs = Loadable({
  loader: () => import("./routes/admin/logs"),
  loading: TopBarProgress,
});

const ShopifyBots = Loadable({
  loader: () => import("./routes/admin/shopify-bots"),
  loading: TopBarProgress,
});

const ShopifyPetitions = Loadable({
  loader: () => import("./routes/admin/shopify-petitions"),
  loading: TopBarProgress,
});

// SUPPORT
const Support = Loadable({
  loader: () => import("./routes/support"),
  loading: TopBarProgress,
});

const Examples = Loadable({
  loader: () => import("./routes/examples"),
  loading: TopBarProgress,
});

const Dash = ({ orderId, isOnBoarding }) => {
  const { user } = useUser();

  if (user?.type === "admin") {
    return (
      <Switch>
        <Route path="/dashboard">
          <DashboardAdmin />
        </Route>
        <Route path="/clientes">
          <Clients />
        </Route>
        <Route path="/facturacion">
          <Invoices />
        </Route>
        <Route path="/chatbots">
          <Chatbots />
        </Route>
        <Route path="/logs">
          <Logs />
        </Route>
        <Route path="/shopify-bots">
          <ShopifyBots />
        </Route>
        <Route path="/shopify-petitions">
          <ShopifyPetitions />
        </Route>
        <Route path="/config">
          <Config />
        </Route>
        <Route path="/analytic">
          <Analytic />
        </Route>
        <Redirect to="/dashboard" />
      </Switch>
    );
  } else if (user?.type === "support") {
    return (
      <Switch>
        <Route path="/tickets">
          <Tickets />
        </Route>
        <Route path="/examples">
          <Examples />
        </Route>
        <Route path="/feedback">
          <Feedback />
        </Route>
        <Redirect to="/tickets" />
      </Switch>
    );
  } else {
    return (
      <Switch>
        <Route path="/dashboard">
          <Dashboard />
        </Route>
        <Route path="/chat">
          <Chat />
        </Route>
        <Route path="/datasource">
          <DataSource />
        </Route>
        <Route path="/tickets">
          <Tickets />
        </Route>
        <Route path="/billing">
          <Billing />
        </Route>
        <Route path="/invoice">
          <Invoice />
        </Route>
        <Route path="/profile">
          <Profile />
        </Route>
        <Route path="/users">
          <Users />
        </Route>
        <Route path="/tiers">
          <Tiers />
        </Route>
        <Route path="/config">
          <Config />
        </Route>
        <Route path="/integrations">
          <Integrations />
        </Route>
        <Route path="/chatBotConfig">
          <ChatBotConfig />
        </Route>
        <Route path="/analytic">
          <Analytic />
        </Route>
        <Route path="/feedback">
          <Feedback />
        </Route>
        <Redirect
          to={
            isOnBoarding ? `/chatBotConfig?order_id=${orderId}` : "/dashboard"
          }
        />
      </Switch>
    );
  }
};

export default Dash;
